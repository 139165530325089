import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Selectors from '../../../src/Selectors';
import ActionCreator from '../../../src/ActionCreator';
import { withPage } from '../../../src/Page';
import * as Icon from '../../../src/Components/Icon';
import Carousel from 'nuka-carousel';
import * as Widget2 from '../../../src/Components/Widget2'
import Login from '../../../src/Components/Login'

const Wrapper = styled.div`
  ${props => props.css || ''}
`;

const TextSection = styled.div`
  padding: 65px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.bgColor || 'white'};
  color: ${props => props.textColor || 'black'};

  & > h1 {
    margin-bottom: 20px;
    color: ${props => props.textColor || 'black'};
  }

  & > p {
    white-space: pre-wrap;
    max-width: 800px;
    color: ${props => props.textColor || 'black'};
  }

  ${props => props.extraCss || ''};
`;

const Section = styled.section`
  padding: 20px;
  max-width: 1024px;
  margin: 0 auto;

  ${props => props.css || ''}
`;

const SectionHeader = styled.header`
  line-height: 40px;
  height: 40px;
  margin-bottom: 10px;
  font-size: 20px;

  & > button {
    margin-left: 5px;
  }

  & > title {
    background-color:#ce2843;
    border-radius: 5px;
    display: inline;
    color: white;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
  }

  ${props => props.css || ''}
`

const SectionItems = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px 0 45px 0;

  ${props => props.css || ''}
`;

const Item = styled.div`
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  cursor: pointer;
  transition: box-shadow 500ms;
  & :hover {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }

  & > figure {
    width: 210px;
    height: 210px;
  }

  & > summary {
    padding: 10px;
    width: 210px;
    height: 90px;
    overflow: hidden;
    background-color: white;
  }
`;

const ItemLandscape = styled.div`
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  cursor: pointer;
  transition: box-shadow 500ms;
  & :hover {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }

  & > figure {
    width: 280px;
    height: 210px;
    position: relative;

    & > summary {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 10px;
      width: 280px;
      height: 60px;
      overflow: hidden;
      color: white;
      background-color: rgba(0,0,0,0.5)
    }
  }
`;

const ItemRound = styled.div`
  margin-right: 10px;

  & > figure {
    width: 140px;
    height: 140px;
    overflow: hidden;
    border-radius: 70px;
    cursor: pointer;
    transition: box-shadow 500ms;
    & :hover {
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
  }

  & > summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    width: 140px;
    height: 70px;
    overflow: hidden;
  }
`;

const SeeMoreRound = styled.div`
  position: absolute;
  right: 2px;
  top: calc(50% - 15px);
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #194586;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`

const BannerFigureCss = `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h1 {
    font-size: 48px;
    color: white;
    text-shadow: 2px 2px #888;
  }

  & > h2 {
    color: white;
    text-shadow: 2px 2px #888;
  }

  @media screen and (max-width: 600px) {
    & > h1 {
      font-size: 24px;
    }
  }
`

class LoginHomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      categories: null,
    }
  }

  componentDidMount() {
    let { profile, appActions } = this.props;

    this.setState({ mounted: true });

    if (profile) {
      new Promise(resolve => setTimeout(resolve, 500))
        .then(appActions.videos.browseCategory)
        .then(result => {
          console.log('videos', result);
          this.setState({
            categories: result,
          })
        })
        .catch(err => {
          //bypass, let it loading
        })
    }
  }

  render() {
    let { profile, } = this.props;
    let { mounted, categories } = this.state;

    return (
      <Wrapper>
        {mounted ? (
          <Carousel
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            autoplay={true}
            autoplayInterval={3000}
            swiping={true}
            wrapAround={true}
          >
            <Style.CarouselItem>
              <Widget2.Figure image={"/images/pages/home/carousel2.jpg"} css={BannerFigureCss}>
                <h1>Merck ART Forum</h1>
              </Widget2.Figure>
            </Style.CarouselItem>
            <Style.CarouselItem>
              <Widget2.Figure image={"/images/pages/home/carousel1.jpg"} css={BannerFigureCss}>
                <h1>Merck ART Forum</h1>
              </Widget2.Figure>
            </Style.CarouselItem>
            <Style.CarouselItem>
              <Widget2.Figure image={"/images/pages/home/carousel3.jpg"} css={BannerFigureCss}>
                <h1>Merck ART Forum</h1>
              </Widget2.Figure>
            </Style.CarouselItem>
          </Carousel>
        ) : (
            <Style.CarouselItem>
              <Widget2.Figure image='http://placehold.it/1000x400/888888/888888/&text=Banner1' css={BannerFigureCss}>
              </Widget2.Figure>
            </Style.CarouselItem>
          )}

        {/*profile && categories && this._renderCategorySections()*/}

        {profile ? (
          <TextSection bgColor="#E62E8B" textColor="white">
            <h1>默克 ART 影音教學平台</h1>
            <p>
              {
                'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. \
Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus \
et magnis dis parturient montes, nascetur ridiculus mus. '
              }
            </p>
          </TextSection>
        ) : (
            <TextSection bgColor="#E62E8B" textColor="white">
              <h1>限醫學專業人員登入</h1>
              <p>
              <Login.Button />
              </p>
            </TextSection>
          )}
      </Wrapper>
    );
  }

  _renderCategorySections = () => {
    let { navActions } = this.props;
    let { categories } = this.state;

    return categories.map((category, cIdx) => {
      return category.own_items.length === 0 ? null : (
        <div key={cIdx} style={{ backgroundColor: '#2dbecd' }}>
          <Section css='position: relative;'>
            <SectionHeader>
              <title>{category.name}</title>
              <Widget2.FlatButton onClick={() => navActions.push(`/video-category/?id=${category.id}`)}>
              <p style={{display:'inline', color: '#194586', fontWeight:800}}>More</p>
            </Widget2.FlatButton>
            </SectionHeader>

            <SectionItems>
              {category.own_items.map((item, idx) => (
                cIdx % 2 === 0 ? (
                  <Item key={item.id} onClick={() => navActions.push(`/video/?id=${item.id}`)}>
                    <Widget2.Figure image={item.image || "http://placehold.it/150x150/888888/c0392b/&text=ComingSoon"} />
                    <summary>
                      <h3>{item.name}</h3>
                      <p>{item.description || 'details...'}</p>
                    </summary>
                  </Item>
                ) : (
                    <ItemLandscape key={item.id} onClick={() => navActions.push(`/video/?id=${item.id}`)}>
                      <Widget2.Figure image={item.image || "http://placehold.it/150x150/888888/c0392b/&text=ComingSoon"}>
                        <summary>
                          {item.display_name || item.name}
                        </summary>
                      </Widget2.Figure>
                    </ItemLandscape>
                  )
              ))}
            </SectionItems>
            <SeeMoreRound>
              <Icon.ArrowForward color='white' onClick={() => navActions.push(`/video-category/?id=${category.id}`)} />
            </SeeMoreRound>
          </Section>
        </div>
      )
    })
  }
}

LoginHomePage = connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }), ActionCreator
)(LoginHomePage);

class HomePage extends React.Component {
  render() {
    let { profile } = this.props;
    console.log('profile', profile);

    return (
      <LoginHomePage key={profile && profile.id || 0} />
    )
  }
}

const Style = {
  CarouselItem: styled.div`
    width: 100%;
    height: 600px;
    position: relative;

    & > figure {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 600px) {
      height: 400px;
    }
  `,
}

export default withPage(connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }), ActionCreator
)(HomePage));
